export interface CounterOptions {
  urlScheme: string;
  debug?: boolean;
}

export interface SendParams {
  counterType: number;
  entityName: string;
  entityId: number;
}

export class Counter {
  private readonly urlScheme: string;
  private readonly options: Omit<CounterOptions, 'urlScheme'>;

  public constructor(options: CounterOptions) {
    this.options = {
      debug: false,
      ...options,
    };

    this.urlScheme = options.urlScheme;
  }

  public send(params: SendParams) {
    const url = this.urlScheme
      .replace('%counterType%', String(params.counterType))
      .replace('%entityName%', params.entityName)
      .replace('%entityId%', String(params.entityId));

    if (this.options.debug) {
      console.log('Counter: send was called', url);
    }

    fetch(url).catch(function (error) {});
  }
}

const initCounter = (element: HTMLElement) => {
  const url = element.dataset.anwCounter;

  if (!url) {
    console.debug('counter was not initialized because it was missing the attribute data-anw-counter');
    return;
  }

  element.addEventListener('click', () => fetch(url).catch(function (error) {}));
};

export default initCounter;
